import React from "react";

const GetInTouch = () => {
  return (
    <React.Fragment>
      <section class="row_am query_section">
        <div
          class="query_inner aos-init aos-animate"
          data-aos="fade-in"
          data-aos-duration="1500"
        >
          <div class="container">
            <span class="banner_shape1">
              <img src="assets/images/banner-shape1.png" alt="image" />
            </span>
            <span class="banner_shape2">
              <img src="assets/images/banner-shape2.png" alt="image" />
            </span>
            <span class="banner_shape3">
              <img src="assets/images/banner-shape3.png" alt="image" />
            </span>

            <div class="section_title">
              <h2>Have any question?</h2>
              <p>
                If you have any question about our product, service, payment or
                company, please contact us.
              </p>
            </div>
            <a href="contact_us" class="btn white_btn">
              CONTACT US NOW
            </a>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default GetInTouch;
