import React from "react";
import { Link } from "react-router-dom";

const Header = (props) => {
  const headerType = props.headerType ? props.headerType : "";
  return (
    <React.Fragment>
      <>
        {/* Preloader */}
        <div id="preloader">
          <div id="loader" />
        </div>
        {/* Header Start */}
        <header class={headerType}>
          {/* container start */}
          <div className="container">
            {/* navigation bar */}
            <nav className="navbar navbar-expand-lg">
              <a className="navbar-brand" href="/">
                {headerType === "white_header" && (
                  <img src="images/white-logo.png" alt="image" />
                )}
                {headerType !== "white_header" && (
                  <img src="images/logo.png" alt="image" />
                )}
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon">
                  {/* <i class="icofont-navigation-menu ico_menu"></i> */}
                  <div className="toggle-wrap">
                    <span className="toggle-bar" />
                  </div>
                </span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto">
                  {/* secondery menu start */}
                  <li className="nav-item ">
                    <a className="nav-link" href="/">
                      Home
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="about_us">
                      About Us
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="our_services">
                      Our Services
                    </a>
                  </li>
                  {/* secondery menu start */}
                  <li className="nav-item has_dropdown">
                    <a className="nav-link" href="#">
                      Pages
                    </a>
                    <span className="drp_btn">
                      <i className="icofont-rounded-down" />
                    </span>
                    <div className="sub_menu">
                      <ul>
                        <li>
                          <a href="terms_and_conditions">Terms & Conditions</a>
                        </li>
                        <li>
                          <a href="privacy_policy">Privacy Policy</a>
                        </li>
                        <li>
                          <a href="refund_policy">Refund Policy</a>
                        </li>
                        <li>
                          <a href="grievance_policy">Grievance Policy</a>
                        </li>
                      </ul>
                    </div>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="contact_us">
                      Contact
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link dark_btn"
                      href="https://app.rechargeindia.app/login"
                    >
                      Login
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            {/* navigation end */}
          </div>
          {/* container end */}
        </header>
      </>
    </React.Fragment>
  );
};

export default Header;
