import React, { useEffect, useState, useContext, useRef } from "react";
import Swal from "sweetalert2";
import Footer from "../Templete/Footer";
import Header from "../Templete/Header";

import FormService from "../../Services/FormService";
import LoadingContext from "../../Store/loading-context";
import HttpError from "../../utill/HttpError";

const ContactUs = () => {
  useEffect(() => {
    document.title = "Contact Us - Recharge India";
  }, []);

  const loadingCtx = useContext(LoadingContext);

  const [message, setMessage] = useState();

  const formRef = useRef();
  const fnameRef = useRef();
  const lnameRef = useRef();
  const emailRef = useRef();
  const mobileRef = useRef();
  const subjectRef = useRef();

  const submitHandler = async () => {
    const fname = fnameRef.current.value;
    const lname = lnameRef.current.value;
    const email = emailRef.current.value;
    const mobile = mobileRef.current.value;
    const subject = subjectRef.current.value;

    if (fname == "") {
      Swal.fire("Opps!", "Please Enter First Name", "error");
      return;
    }

    if (lname == "") {
      Swal.fire("Opps!", "Please Enter Last Name", "error");
      return;
    }

    if (email == "") {
      Swal.fire("Opps!", "Please Enter Email ID", "error");
      return;
    }

    if (mobile == "") {
      Swal.fire("Opps!", "Please Enter Mobile Number", "error");
      return;
    }

    if (subject == "") {
      Swal.fire("Opps!", "Please Enter Subject", "error");
      return;
    }

    if (message == "") {
      Swal.fire("Opps!", "Please Enter Message", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const responseData = await FormService.contactForm(
        fname + " " + lname,
        email,
        mobile,
        subject,
        message
      );

      const status = responseData.status;
      const message2 = responseData.message;

      if (status === "SUCCESS") {
        formRef.current.reset();
        Swal.fire("Success!", message2, "success");
      } else {
        Swal.fire("Opps!", message2, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }

    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <div class="page_wrapper">
        <Header headerType="white_header" />

        {/* BredCrumb-Section */}
        <div className="bred_crumb">
          <div className="container">
            {/* shape animation  */}
            <span className="banner_shape1">
              {" "}
              <img src="assets/images/banner-shape1.png" alt="image" />{" "}
            </span>
            <span className="banner_shape2">
              {" "}
              <img src="assets/images/banner-shape2.png" alt="image" />{" "}
            </span>
            <span className="banner_shape3">
              {" "}
              <img src="assets/images/banner-shape3.png" alt="image" />{" "}
            </span>
            <div className="bred_text">
              <h1>Contact us</h1>
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <span>»</span>
                </li>
                <li>
                  <a href="contact_us">Contact us</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Contact Us Section Start */}
        <section
          className="contact_page_section"
          style={{ paddingBottom: "50px" }}
        >
          <div className="container">
            <div className="contact_inner">
              <div className="contact_form">
                <div className="section_title">
                  <h2>Get in touch with us!</h2>
                  <p>Fill up form below, our team will get back soon</p>
                </div>
                <form action="">
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="First Name"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Last Name"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      placeholder="Phone"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      placeholder="Email"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      placeholder="Subject"
                      defaultValue={""}
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      placeholder="Your message"
                      defaultValue={""}
                    />
                  </div>
                  <div className="form-group mb-0">
                    <button type="submit" className="btn puprple_btn">
                      SEND MESSAGE
                    </button>
                  </div>
                </form>
              </div>
              <div className="contact_info">
                <div
                  class="section_title"
                  style={{ paddingTop: "30px", paddingBottom: "30px" }}
                >
                  <h2>
                    Have any <span>question?</span>
                  </h2>
                  <p>
                    If you have any question about our product, service, payment
                    or company, please contact us.
                  </p>
                </div>
                <ul className="contact_info_list">
                  <li>
                    <div className="img">
                      <img src="assets/images/mail_icon.png" alt="image" />
                    </div>
                    <div className="text">
                      <span>Email Us</span>
                      <a href="mailto:helpdesk@rechargeindia.app">
                        helpdesk@rechargeindia.app
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="img">
                      <img src="assets/images/call_icon.png" alt="image" />
                    </div>
                    <div className="text">
                      <span>Call Us</span>
                      <a href="tel:+918099175720">+91 8099-175-720</a>
                    </div>
                  </li>
                  <li>
                    <div className="img">
                      <img src="assets/images/location_icon.png" alt="image" />
                    </div>
                    <div className="text">
                      <span>Visit Us</span>
                      <p>
                        House No 312, Pachim Kopatigaon L P School, Koupatigaon,
                        Silbori Dalgaon, Koupatigson, Dallang, Assam - 784114
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* Contact Us Section End */}

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default ContactUs;
