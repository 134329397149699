import React from "react";

import "./testimonials-style.css";

const Testimonials = () => {
  return (
    <React.Fragment>
      <div class="testimonials-clean" style={{ paddingBottom: "50px" }}>
        <div class="container">
          <div class="intro">
            <div className="section_title">
              <h2>
                What Our <span>User Say</span>
              </h2>
            </div>
            <p class="text-center">
              Our customers love the convenience, speed, and reliability we
              offer. Here’s what they have to say about their experience with
              us!
            </p>
          </div>
          <div class="row people">
            <div class="col-md-6 col-lg-4 item">
              <div class="box">
                <p class="description">
                  “I’ve tried several recharge apps, but this one stands out for
                  its speed and ease of use. The process is smooth, and I love
                  the instant notifications. Highly recommended!”
                </p>
              </div>
              <div class="author">
                <img class="rounded-circle" src="images/user-icon.png" />
                <h5 class="name">Sanju</h5>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 item">
              <div class="box">
                <p class="description">
                  “This app has made my life so much easier. I can recharge my
                  phone, DTH, and even pay bills all in one place. The interface
                  is user-friendly, and the customer support is excellent!”
                </p>
              </div>
              <div class="author">
                <img class="rounded-circle" src="images/user-icon.png" />
                <h5 class="name">Swapan</h5>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 item">
              <div class="box">
                <p class="description">
                  “I’ve been using this app for months now, and it’s been a
                  game-changer. The transactions are quick, and I’ve never faced
                  any issues. The cashback offers are a nice bonus too!”
                </p>
              </div>
              <div class="author">
                <img class="rounded-circle" src="images/user-icon.png" />
                <h5 class="name">Amit</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Testimonials;
