import React from "react";

const AboutCompany = () => {
  return (
    <React.Fragment>
      {/* App-Solution-Section-Start */}
      <section className="row_am app_solution_section">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="row">
            <div className="col-lg-6">
              {/* UI content */}
              <div className="app_text">
                <div className="section_title">
                  <h2>
                    About <span>Recharge India</span>
                  </h2>
                </div>
                <p style={{ textAlign: "justify" }}>
                  Welcome to <b>Recharge India Commission App</b>, your trusted
                  partner in seamless and efficient recharge solutions across
                  India. We are a dedicated platform designed to empower
                  individuals, small businesses, and entrepreneurs by providing
                  a reliable and user-friendly service for mobile, DTH, and
                  utility recharges. Our mission is to simplify the recharge
                  process, offering a fast, secure, and hassle-free experience
                  for our users.
                </p>

                <div style={{ paddingTop: "5px" }}>
                  <a href="about_us" class="btn white_btn">
                    Read More
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                style={{
                  paddingTop: "25px",
                }}
              ></div>
              <img
                style={{
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                src="images/about-us.png"
                width={450}
              />
            </div>
          </div>
        </div>
        {/* container end */}
      </section>
      {/* App-Solution-Section-end */}
    </React.Fragment>
  );
};

export default AboutCompany;
