import React from "react";

const HowItWorks = () => {
  return (
    <section className="row_am how_it_works" id="how_it_work">
      {/* container start */}
      <div className="container">
        <div className="how_it_inner">
          <div
            className="section_title aos-init aos-animate"
            data-aos="fade-up"
            data-aos-duration={1500}
            data-aos-delay={300}
          >
            {/* h2 */}
            <h2>
              <span>Create an account</span> - 3 easy steps
            </h2>
            {/* p */}
            <p>
              Lorem Ipsum is simply dummy text of the printing and typese tting{" "}
              <br /> indus orem Ipsum has beenthe standard dummy.
            </p>
          </div>
          <div className="step_block">
            {/* UL */}
            <ul>
              {/* step */}
              <li>
                <div
                  className="step_text aos-init aos-animate"
                  data-aos="fade-right"
                  data-aos-duration={1500}
                >
                  <h4>Download app</h4>

                  <p>
                    Head to your app store and search for Recharge India App.
                    Tap “Download” to get it installed on your device.
                  </p>
                </div>
                <div className="step_number">
                  <h3>01</h3>
                </div>
                <div
                  className="step_img aos-init aos-animate"
                  data-aos="fade-left"
                  data-aos-duration={1500}
                >
                  <img src="assets/images/download_app.jpg" alt="image" />
                </div>
              </li>
              {/* step */}
              <li>
                <div
                  className="step_text aos-init aos-animate"
                  data-aos="fade-left"
                  data-aos-duration={1500}
                >
                  <h4>Create account</h4>
                  <p>
                    Open the app and follow the easy sign-up process. Sign up
                    free quickly with just a few details.
                  </p>
                </div>
                <div className="step_number">
                  <h3>02</h3>
                </div>
                <div
                  className="step_img aos-init aos-animate"
                  data-aos="fade-right"
                  data-aos-duration={1500}
                >
                  <img src="assets/images/create_account.jpg" alt="image" />
                </div>
              </li>
              {/* step */}
              <li>
                <div
                  className="step_text aos-init aos-animate"
                  data-aos="fade-left"
                  data-aos-duration={1500}
                >
                  <h4>
                    All set! Enjoy a seamless <br />
                    recharge experience
                  </h4>
                  <p>
                    Congratulations! Your account is now ready. Start exploring
                    all the features and enjoy seamless recharges right away.
                  </p>
                </div>
                <div className="step_number">
                  <h3>03</h3>
                </div>
                <div
                  className="step_img aos-init aos-animate"
                  data-aos="fade-left"
                  data-aos-duration={1500}
                >
                  <img src="assets/images/enjoy_app.jpg" alt="image" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* container end */}
    </section>
  );
};

export default HowItWorks;
